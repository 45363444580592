import {
  sortEvents,
  EVENTS_APP_ID,
  EVENT_FILTER_TYPE,
  ExperimentNames,
  isRtlLanguage,
} from '@wix/wix-events-commons-statics'
import {ExperimentsBag, ControllerParams, CreateControllerFn, TFunction} from '@wix/yoshi-flow-editor'
import {bindActionCreators, PreloadedState, Store} from 'redux'
import {setBaseEnvironment, setFormFactor} from '../../commons/actions/environment'
import {watchInstance} from '../../commons/actions/instance'
import {updateSiteSettings} from '../../commons/actions/site-settings'
import {createUouBiMiddlewareWithBiParams, createUsersBiMiddlewareWithBiParams} from '../../commons/bi/bi'
import {UserRole} from '../../commons/enums'
import {isResponsiveEditor} from '../../commons/selectors/environment'
import {decodeInstance} from '../../commons/selectors/instance'
import {getMultilingualInitialState} from '../../commons/services/multilingual'
import {getQueryParams} from '../../commons/services/navigation'
import {isEventsInMembersInstalled} from '../../commons/utils/members-api'
import {createReduxStore} from '../../commons/utils/store'
import {getLanguage, getPageInfo, isEditor} from '../../commons/utils/wix-code-api'
import {EMPTY_CATEGORY_FILTER} from '../../commons/constants/settings'
import {fillSettingsDefaults} from './defaultParams'
import settingsParams from './settingsParams'
import {
  addCalendarMonth,
  closeMonthlyCalendarEvent,
  closeMonthlyCalendarPopup,
  openMonthlyCalendarEvent,
  openMonthlyCalendarPopup,
  resetCalendar,
  subCalendarMonth,
} from './Widget/actions/calendar-layout'
import {initSettings, updateStyleParams, updateStyles} from './Widget/actions/component'
import {createEvent, shareEvent} from './Widget/actions/event'
import {loadEventsPage, reloadEvents} from './Widget/actions/events'
import {closeListLayoutItems, openListLayoutItem, widgetLoaded} from './Widget/actions/layout'
import {addLoginListener, loadMembersForEvents, openMembersModal} from './Widget/actions/members'
import {navigateToPage, onLinkNavigatedToPage} from './Widget/actions/navigate-to-page'
import {updateSettings} from './Widget/actions/sdk'
import {setVeloCategoryFilter} from './Widget/actions/velo-overrides'
import * as eventsUou from './Widget/bi/uou-bi-events-map'
import * as eventsUsers from './Widget/bi/users-bi-events-map'
import {AppProps} from './Widget/components/app/interfaces'
import {datesMiddleware} from './Widget/middlewares/date'
import reducers from './Widget/reducers'
import {getDynamicCategoryId} from './Widget/selectors/velo-overrides'
import {isPaidPlansInstalled} from './Widget/services/apps'
import {Actions, State, StoreExtraArgs} from './Widget/types/state'
import {userEventsLogger} from './Widget/user-events-logger'
import {Api} from './Widget/utils/api'

const createWidgetController: CreateControllerFn = async (controllerParams: ControllerParams) => {
  const experiments = controllerParams.flowAPI.experiments.all()
  const serverApi = new Api(controllerParams)
  const store: any = await createStore({controller: controllerParams, serverApi, experiments})

  return {
    pageReady: async () => {
      await store.dispatch(
        initSettings({
          t: controllerParams.flowAPI.translations.t as TFunction,
          fillSettingsDefaults,
        }),
      )

      pageReady({controllerParams, store, serverApi})
    },
    exports: () => ({
      set categoryId(id: string) {
        store.dispatch(setVeloCategoryFilter(id))
        store.dispatch(reloadEvents())
      },
      get categoryId() {
        return getDynamicCategoryId(store.getState(), controllerParams.flowAPI)
      },
    }),
    updateAppSettings: async ($w, action) => {
      if (store) {
        setTimeout(() => {
          store.dispatch(updateSettings(action as any, store.getState().component.id))
        }, 100)
      }
    },
    updateConfig: ($w, newConfig) => {
      store.dispatch(
        updateStyles({
          numbers: newConfig.style.styleParams.numbers,
          booleans: newConfig.style.styleParams.booleans,
        }),
      )
    },
  }
}

interface PageReadyParams {
  controllerParams: ControllerParams
  store: any
  serverApi: Api
}

const pageReady = async ({store, serverApi, controllerParams}: PageReadyParams) => {
  const {controllerConfig: controller} = controllerParams
  const language = getLanguage(controller.wixCodeApi)

  const [pageInfo, pageUrl] = await Promise.all([
    getPageInfo(controller.wixCodeApi),
    controller.wixCodeApi.site.getSectionUrl({
      sectionId: 'events',
      appDefinitionId: EVENTS_APP_ID,
    }),
  ])
  const state = store.getState()

  const props: Partial<AppProps> = {
    pageUrl,
    baseUrl: controller.wixCodeApi.location.baseUrl,
    queryParams: getQueryParams(controller.wixCodeApi),
    pageInfo,
    state,
    actions: exportedActions({
      store,
    }),
    isRTL: isRtlLanguage(language),
    // @ts-expect-error
    fitToContentHeight: true,
  }

  if (state.membersEnabled) {
    serverApi.onLogin(() => {
      store.dispatch(loadMembersForEvents())
    })
  }

  controller.setProps(props)
  subscribeToStateChanges(controllerParams, store)
}

interface CreateStoreParams {
  controller: ControllerParams
  serverApi: Api
  experiments: ExperimentsBag
}

const createStore = async ({controller, serverApi, experiments}: CreateStoreParams) => {
  const {
    controllerConfig: {wixCodeApi, platformAPIs, appParams, compId, config},
    flowAPI,
  } = controller

  let initialData: Awaited<ReturnType<typeof getInitialData>>
  const warmupDataKey = `widget${controller.controllerConfig.compId}`
  if (!flowAPI) {
    throw new Error('NO flow api')
  }
  if (flowAPI.experiments.enabled(ExperimentNames.UseWarmupState) && flowAPI.environment.isSSR) {
    initialData = await getInitialData({serverApi, controller, experiments})
    wixCodeApi.window.warmupData.set(warmupDataKey, initialData)
  } else {
    initialData =
      wixCodeApi.window.warmupData.get(warmupDataKey) ?? (await getInitialData({serverApi, controller, experiments}))
  }

  const biParams = {
    wixCodeApi,
    platformAPIs,
    appParams,
    compId,
    user: {
      aid: initialData.instance.aid,
      uid: initialData.instance.uid,
    },
  }
  const biMiddleware = [
    createUsersBiMiddlewareWithBiParams(biParams, eventsUsers),
    createUouBiMiddlewareWithBiParams(biParams, eventsUou),
  ]

  const userEventsLoggerMiddleware = userEventsLogger({wixCodeApi})

  const store = createReduxStore<State, StoreExtraArgs>({
    reducers,
    initialData,
    extraArguments: {
      serverApi,
      wixCodeApi,
      compId,
      flowAPI: controller.flowAPI,
    },
    middleware: [...biMiddleware, userEventsLoggerMiddleware, datesMiddleware],
  })

  store.dispatch(updateStyleParams(config.style.styleParams))
  store.dispatch(setBaseEnvironment() as any)
  store.dispatch(addLoginListener() as any)

  watchInstance(controller.controllerConfig, store.dispatch)

  return store
}

const subscribeToStateChanges = (controllerParams: ControllerParams, store: Store) => {
  const onStateChange = () => {
    const state: State = store.getState()
    controllerParams.controllerConfig.setProps({
      state,
    })
  }

  store.subscribe(onStateChange)
}

interface GetInitialDataParams {
  serverApi: Api
  experiments: ExperimentsBag
  controller: ControllerParams
}

const getInitialData = async ({
  serverApi,
  experiments,
  controller,
}: GetInitialDataParams): Promise<PreloadedState<State>> => {
  const {wixCodeApi, appParams, config: controllerConfig} = controller.controllerConfig
  const {flowAPI} = controller
  const filterType = flowAPI.settings.get(settingsParams.filterType) as number
  const sortOrder = flowAPI.settings.get(settingsParams.sortOrder) as number
  const categoryId = flowAPI.settings.get(settingsParams.categoryId) as string

  const [membersInstalled, paidPlansInstalled] = await Promise.all([
    flowAPI.environment.isADI ? false : isEventsInMembersInstalled(wixCodeApi),
    flowAPI.environment.isADI ? false : isPaidPlansInstalled(wixCodeApi),
  ])

  const instance = appParams.instance
  const decodedInstance = decodeInstance(instance)
  const isTemplate = decodedInstance.siteIsTemplate
  const responsive = isResponsiveEditor(controllerConfig)
  const showcase = isTemplate || isEditor(wixCodeApi)
  const {widgetType, listLayout, recurringFilter} = controllerConfig.style.styleParams.numbers
  const {compId, language, locale, tz, viewMode} = serverApi

  const {
    component: {events = [], config, id},
    siteSettings,
    demoEvents,
    hasMoreEvents,
    dates,
  } = await serverApi.getWidgetData({
    members: membersInstalled,
    paidPlans: paidPlansInstalled,
    responsive,
    showcase,
    widgetType,
    listLayout,
    recurringFilter,
    categoryId: categoryId === EMPTY_CATEGORY_FILTER ? undefined : categoryId,
    noCategoryIdFallback: categoryId === EMPTY_CATEGORY_FILTER,
    compId,
    language,
    locale,
    tz,
    viewMode,
    filterType,
    sortOrder: filterType === EVENT_FILTER_TYPE.MANUAL ? sortOrder : undefined,
  })

  return {
    experiments,
    events: {
      events: filterType === EVENT_FILTER_TYPE.MANUAL ? events : sortEvents(events),
      hasMore: hasMoreEvents,
      moreLoading: false,
      filterType,
    },
    siteSettings,
    demoEvents,
    multilingual: getMultilingualInitialState(wixCodeApi),
    component: {
      ...config,
      id,
    },
    membersEnabled: membersInstalled,
    paidPlansEnabled: paidPlansInstalled,
    memberLoggedIn: wixCodeApi.user.currentUser.loggedIn,
    owner: wixCodeApi.user.currentUser.role === UserRole.ADMIN,
    instance: {
      instance,
      ...decodedInstance,
    },
    calendarLayout: {
      ...(dates.calendar ?? {}),
      loadedPages: dates.calendar?.referenceDate ? [dates.calendar.referenceDate] : [],
    },
    dates,
  } as PreloadedState<State>
}

const exportedActions = ({store}) => {
  const dispatchActions = {
    addCalendarMonth,
    subCalendarMonth,
    resetCalendar,
    createEvent,
    updateSiteSettings,
    navigateToPage,
    onLinkNavigatedToPage,
    updateStyles,
    updateSettings,
    setBaseEnvironment,
    openListLayoutItem,
    closeListLayoutItems,
    openMonthlyCalendarPopup,
    closeMonthlyCalendarPopup,
    openMonthlyCalendarEvent,
    closeMonthlyCalendarEvent,
    widgetLoaded,
    openMembersModal,
    shareEvent,
    setFormFactor,
    loadEventsPage,
  }

  const actions: Actions = {
    ...bindActionCreators(dispatchActions, store.dispatch),
  }

  return actions
}

export default createWidgetController
